import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import { HTMLContent } from '../components/Content'
import styles from '../components/iframe.module.css'

export default class Page extends React.Component {
  render() {
    const {
      title,
      metaDescription,
      metaKeywords,
    } = this.props.data.markdownRemark.frontmatter

    const content = this.props.data.markdownRemark.html

    return (
      <>
        <Helmet>
          <title>{"Smangtasia - " + title}</title>
          <meta name="description" content={metaDescription} />
          <meta name="keywords" content={metaKeywords} />
          <meta property="og:title" content={"Smangtasia - " + title} />
        </Helmet>

        <Layout>

          <section className="page-content">
            <div className={styles.iframeWrapper} >
              <iframe className={styles.iframe} title="map" id="gmap_canvas" src="https://maps.google.com/maps?q=stone%20tavern%20farm%20llc&t=k&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </div>
            <HTMLContent content={content} />
          </section>

        </Layout>
      </>
    )
  }
}

export const locationPageQuery = graphql`
  query LocationPageQuery($title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title }}) {
      html
      frontmatter {
        title
        metaKeywords
        metaDescription
      }
    }
  }
`
